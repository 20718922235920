<template>
  <form action="" @submit.prevent="submit">
    <div>
      <AlertSuccess v-if="alertSuccess" :message="messageAlert" />
      <div v-if="alertFailed">
        <AlertFailed
          v-for="(item, index) in messageAlert"
          :key="index"
          :message="item"
          :dismissable="true"
        />
      </div>
    </div>
    <div v-if="isLoading" class="p-5">Loading data...</div>
    <div v-else class="intro-y col-span-12 lg:col-span-6">
      <div
        v-if="isLoading"
        class="absolute bg-white w-full h-full z-100 flex justify-center items-center z-50"
      >
        <div class="opacity-100">
          <LoadingSpinner :is-loading="isLoading" :use-state="useState" />
        </div>
      </div>
      <div class="grid grid-cols-12 gap-x-8">
        <div
          class="col-span-12 mb-4 py-6 bg-gray-50 border-2 border-dashed dark:border-dark-5 rounded-md"
        >
          <div class="col-span-12 mt-2 form-inline">
            <label class="form-label font-medium w-52">Nama Dosen/Tendik</label>
            {{
              inpassing.sdm?.dosen
                ? inpassing.sdm?.dosen.NM_DOSEN
                : inpassing.sdm?.tendik.NM_TENDIK
            }}
          </div>
          <div class="col-span-12 mt-2 form-inline">
            <label class="form-label font-medium w-52">NIK</label>
            {{
              inpassing.sdm?.dosen
                ? inpassing.sdm?.dosen.KODE
                : inpassing.sdm?.tendik.KODE
            }}
          </div>
        </div>
        <div class="col-span-12 mt-4"><hr /></div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52"
            >Tingkat Staff <span class="text-red-900">*</span></label
          >
          <TailSelect
            v-model="inpassing['id_pangkat_gol']"
            :options="{
              search: true,
              classNames: 'form-control',
              deselect: false,
            }"
          >
            <option
              v-for="list in ref_pangkatgol"
              :key="list.value"
              :value="list.value"
              :selected="list.selected"
            >
              {{ list.text }}
            </option>
          </TailSelect>
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52"
            >Nomor SK Inpassing <span class="text-red-900">*</span></label
          >
          <input
            v-model="inpassing['sk_pangkat']"
            class="form-control focus:ring-0"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52"
            >Tanggal SK <span class="text-red-900">*</span></label
          >
          <input
            v-model="inpassing['tgl_sk_pangkat']"
            class="form-control focus:ring-0"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52"
            >Terhitung Mulai Tanggal <span class="text-red-900">*</span></label
          >
          <input
            v-model="inpassing['tmt_sk_pangkat']"
            class="form-control focus:ring-0"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52"
            >Angka Kredit <span class="text-red-900">*</span></label
          >
          <input
            v-model="inpassing['angka_kredit']"
            class="form-control focus:ring-0"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52"
            >Masa Kerja (Tahun) <span class="text-red-900">*</span></label
          >
          <input
            v-model="inpassing['masa_kerja_gol_thn']"
            class="form-control focus:ring-0"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52"
            >Masa Kerja (Bulan) <span class="text-red-900">*</span></label
          >
          <input
            v-model="inpassing['masa_kerja_gol_bln']"
            class="form-control focus:ring-0"
          />
        </div>
      </div>

      <hr class="mt-6" />

      <div class="flex justify-end flex-col md:flex-row gap-2 mt-6">
        <button type="submit" class="btn py-3 btn-primary w-full md:w-52">
          <SaveIcon class="w-4 h-4 mr-2" /> Simpan Prubahan
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { ref as refmodel, sdm } from "@/services/models";
import AlertSuccess from "@/components/alerts/AlertSuccess";
import AlertFailed from "@/components/alerts/AlertFailed";
import LoadingSpinner from "@/components/loading-spinner/Main";
import moment from "moment";

export default {
  components: {
    AlertSuccess,
    AlertFailed,
    LoadingSpinner,
  },
  props: {},
  data() {
    return {
      messageAlert: [],
      isLoading: false,
      useState: false,
      id: this.$route.params.id,
      inpassing: [],
      ref_pangkatgol: [],
      onUploadProgress: [],
    };
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
  },
  watch: {},
  mounted() {
    this.getPangkatGol();
    this.getDetailInpassing();
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async getPangkatGol() {
      let defaultOpt = {
        id: 0,
        value: "",
        text: "Pilih Golongan/Pangkat",
        selected: true,
      };
      this.ref_pangkatgol.push(defaultOpt);
      refmodel.pangkatgolongan.getAll().then((res) => {
        if (res.status === 200) {
          res.data.data.forEach((element) => {
            this.ref_pangkatgol.push({
              id: element.id_pangkat_gol,
              value: element.id_pangkat_gol,
              value2: element.kode_gol,
              text: element.kode_gol + " (" + element.nm_pangkat + ")",
              selected: false,
            });
          });
        }
      });
    },
    async getDetailInpassing() {
      if (this.id?.length > 0) {
        this.isLoading = true;
        sdm.InpassingSdm.getCustomPath(`getDetailInpassing/${this.id}`)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                this.inpassing = res.data.data;

                this.$emit("detailDataInpassing", this.inpassing);
              }
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    submit() {
      this.isLoading = true;
      this.messageAlert = [];

      // console.log(fd);
      let formdata = {
        ...this.inpassing,
      };

      if (this.id?.length > 0) {
        sdm.InpassingSdm.postCustomPath(
          "update_inpassing/" + this.id,
          formdata
        ).then((res) => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              this.alertSuccess = true;
              this.messageAlert = res.data.message;

              this.$store.dispatch("main/isSuccess", true);
              this.$store.dispatch("main/responseMessage", res.data.message);
            } else {
              Object.keys(res.data.message).forEach((key) => {
                this.messageAlert.push(res.data.message[key][0]);
              });
            }
          }

          this.isLoading = false;
          this.scrollToTop();
        });
      }
    },
  },
};
</script>

<style scoped>
.form-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputfile {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  cursor: pointer;
  padding: 10px;
  opacity: 0;
}

.inputfile + .fileinfo {
  font-size: 1rem;
  font-weight: bold;
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  height: 120px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.inputfile:focus + .fileinfo,
.inputfile + .fileinfo:hover,
.inputfile:hover + .fileinfo {
  cursor: pointer;
}

.inputfile + .fileinfo {
  cursor: pointer;
}
</style>
