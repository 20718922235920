<template>
  <MainLayout>
    <form action="" @submit.prevent="submit">
      <div class="intro-y mt-5 mr-4">
        <div
          v-if="isLoading"
          class="absolute bg-white w-full h-full z-100 flex justify-center items-center z-50"
        >
          <div class="opacity-100">
            <LoadingSpinner :is-loading="isLoading" :use-state="useState" />
          </div>
        </div>
        <div class="grid grid-cols-12 gap-6">
          <div class="col-span-12 2xl:col-span-12 -mb-10 pb-10">
            <div class="intro-y box pb-6">
              <div
                class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
              >
                <h2 class="font-medium text-base mr-auto">
                  <FileIcon class="w-5 h-5 mr-2"></FileIcon> Rincian Jabatan
                  Fungsional
                </h2>
              </div>

              <div class="grid grid-cols-12 gap-x-8 p-6">
                <div
                  class="bg-gray-50 col-span-12 lg:col-span-12 mt-2 mb-6 py-5"
                >
                  <h2 class="font-medium text-base mx-6 mb-3">Status Ajuan</h2>
                  <div
                    class="px-2 pb-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                  >
                    <div class="text-gray-500 dark:text-gray-300">Status</div>
                    <div class="mt-2 dark:text-white sm:mt-0 sm:col-span-2">
                      <span
                        v-if="status_ajuan == 0"
                        class="font-medium text-yellow-500"
                        >DIAJUKAN</span
                      >
                      <span
                        v-else-if="status_ajuan == 1"
                        class="font-medium text-green-500"
                        >DISETUJUI</span
                      >
                      <span
                        v-else-if="status_ajuan == 2"
                        class="font-medium text-red-500"
                        >DITOLAK</span
                      >
                    </div>
                  </div>
                  <div
                    class="px-2 pb-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                  >
                    <div class="text-gray-500 dark:text-gray-300">
                      Riwayat Ajuan
                    </div>
                    <div class="mt-2 dark:text-white sm:mt-0 sm:col-span-2">
                      <div class="mb-1">
                        <b>DIAJUKAN</b> pada tanggal
                        <b>{{ $h.tanggal(detailJabfung.tgl_create) }}</b>
                      </div>
                      <div class="mb-1">
                        Staus
                        <b>{{ status_ajuan == 1 ? "DISETUJUI" : "DITOLAK" }}</b>
                        diperbarui pada tanggal
                        <b>{{ $h.tanggal(detailJabfung.tgl_ajuan) }}</b>
                      </div>
                    </div>
                  </div>
                  <div
                    class="px-2 pb-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                  >
                    <div class="text-gray-500 dark:text-gray-300">
                      Keterangan Periksa
                    </div>
                    <div class="mt-2 dark:text-white sm:mt-0 sm:col-span-2">
                      {{ detailJabfung.keterangan_ajuan }}
                    </div>
                  </div>
                </div>
                <dataJabfung
                  :jabatan-fungsional="detailJabfung"
                  :is-saving="isSaving"
                  @simpanBio="onSimpanBio"
                />
                <div
                  class="col-span-12 lg:col-span-12 mt-6 grid grid-cols-12 mb-6 rounded bg-white border-2 border-dashed dark:border-dark-5 rounded-md py-6"
                >
                  <div class="col-span-12 lg:col-span-12">
                    <h2
                      class="font-medium text-base mx-6 mb-3 pb-5 border-b border-gray-200 dark:border-dark-5"
                    >
                      <file-icon class="w-5 h-5 mr-1"></file-icon> Dokumen
                      Lampiran Berkas Jabatan Fungsional (JJA)
                    </h2>
                  </div>
                  <div
                    v-if="detailJabfung.sdm_dokumen > '0'"
                    class="col-span-12 lg:col-span-12"
                  >
                    <div
                      v-for="(item, index) in detailJabfung.sdm_dokumen"
                      :key="index"
                      class="flex items-center mt-5 px-5"
                    >
                      <div class="file">
                        <div href="" class="w-12 file__icon file__icon--file">
                          <div class="file__icon__file-name text-xs">
                            {{ item.jenis_file ? item.jenis_file : "File" }}
                          </div>
                        </div>
                      </div>
                      <div class="ml-4">
                        <div class="font-medium" href="">{{ item.nama }}</div>
                        <div class="text-gray-600 text-xs mt-0.5">
                          {{ item.keterangan }}
                        </div>
                        <div class="text-gray-400 text-xs mt-0.5">
                          Updated:
                          {{
                            $h.tanggal(
                              item.tanggal_upload,
                              "DD MMMM YYYY hh:mm:ss"
                            )
                          }}
                        </div>
                      </div>
                      <div
                        class="flex flex-col items-end w-full h-full align-middle mt-3 xl:mt-0 flex-1 xl:text-right"
                      >
                        <a
                          v-if="item.nama_file"
                          class="btn btn-sm btn-primary w-56"
                          target="_blank"
                          :href="
                            download_url.slice(0, download_url.length - 4) +
                            'storage/sdm/jabfung/' +
                            item.nama_file
                          "
                          download
                        >
                          <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download
                          File
                        </a>
                        <div class="leading-relaxed text-gray-600 text-xs mt-2">
                          {{ item.nama_file ? item.nama_file : "Not found!" }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="col-span-12 lg:col-span-12">
                    <div class="flex items-center mt-5 px-5 text-gray-500">
                      <AlertCircleIcon class="w-4 h-4 mr-2"></AlertCircleIcon>
                      Tidak ada dokumen terlampir
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </MainLayout>
</template>

<script>
import MainLayout from "@/layouts/main/MainLayout";
import dataJabfung from "./partial/dataJabfung";
import LoadingSpinner from "@/components/loading-spinner/Main";
import { sdm } from "@/services/models";

export default {
  components: {
    MainLayout,
    dataJabfung,
    LoadingSpinner,
  },
  data() {
    return {
      editmode: false,
      isLoading: false,
      isSaving: false,
      useState: false,
      id: this.$route.params.id,
      status_ajuan: [],
      detailJabfung: [],
      download_url: process.env.VUE_APP_API,
    };
  },
  mounted() {
    this.getDetailJabfung();
  },
  methods: {
    async getDetailJabfung() {
      if (this.id?.length > 0) {
        this.isLoading = true;
        sdm.JabfungSdm.getCustomPath(`getDetailJabfung/${this.id}`)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                this.detailJabfung = res.data.data;
                this.status_ajuan = this.detailJabfung.status_ajuan;
              }
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    onSimpanBio(value) {
      if (value == true) {
        this.editmode = false;
        this.isSaving = true;
        this.isLoading = false;
      }
    },
  },
};
</script>
<style scoped>
.disabled-edit {
  cursor: not-allowed;
}
</style>
