<template>
  <MainLayout>
    <div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-5">
        <h2 class="text-lg font-medium mr-auto">Jabatan Fungsional</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <router-link
            :to="{ name: 'create-pangkat-inpassing' }"
            class="btn btn-primary shadow-md mr-2"
            ><PlusCircleIcon class="w-4 h-4 mr-1" /> Tambah</router-link
          >

          <button class="btn btn-default shadow-md mr-2" @click="getItems">
            <RefreshCcwIcon class="w-4 h-4" />
          </button>
        </div>
      </div>
      <!-- BEGIN: HTML Table Data -->
      <div class="intro-y box p-5 mt-5">
        <AlertSuccess v-if="alertSuccess" :message="messageAlert" />
        <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
          <form
            id="tabulator-html-filter-form"
            class="xl:flex sm:mr-auto"
            @submit.prevent="onFilter"
          >
            <div class="sm:flex items-center sm:mr-4">
              <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                >Field</label
              >
              <select
                id="tabulator-html-filter-field"
                v-model="filter.field"
                class="form-select w-full sm:w-32 xxl:w-full mt-2 sm:mt-0 sm:w-auto"
              >
                <option value="sk_pangkat">No. SK</option>
              </select>
            </div>
            <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
              <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                >Type</label
              >
              <select
                id="tabulator-html-filter-type"
                v-model="filter.type"
                class="form-select w-full mt-2 sm:mt-0 sm:w-auto"
              >
                <option value="like" selected>like</option>
                <option value="=">=</option>
                <option value="<">&lt;</option>
                <option value="<=">&lt;=</option>
                <option value=">">></option>
                <option value=">=">>=</option>
                <option value="!=">!=</option>
              </select>
            </div>
            <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
              <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                >Value</label
              >
              <input
                id="tabulator-html-filter-value"
                v-model="filter.value"
                type="text"
                class="form-control sm:w-40 xxl:w-full mt-2 sm:mt-0"
                placeholder="Search..."
              />
            </div>
            <div class="mt-2 xl:mt-0">
              <button
                id="tabulator-html-filter-go"
                type="button"
                class="btn btn-primary w-full sm:w-16"
                @click="onFilter"
              >
                Go
              </button>
              <button
                id="tabulator-html-filter-reset"
                type="button"
                class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
                @click="onResetFilter"
              >
                Reset
              </button>
            </div>
          </form>
        </div>
        <div class="overflow-x-auto scrollbar-hidden">
          <div
            id="tabulator"
            ref="tableRef"
            class="mt-5 table-report table-report--tabulator"
          ></div>
        </div>
      </div>
      <!-- END: HTML Table Data -->
    </div>

  </MainLayout>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import feather from "feather-icons";
import { useStore } from "vuex";
import router from "@/router";
import { tblTabulator } from "@/utils/tabulator";
import { sdm } from "@/services/models";
import MainLayout from "@/layouts/main/MainLayout";
import AlertSuccess from "@/components/alerts/AlertSuccess";
import cash from "cash-dom/dist/cash";
import { helper } from "@/utils/helper";

export default defineComponent({
  components: {
    MainLayout,
    AlertSuccess,
  },
  setup() {
    const idData = ref();
    const store = useStore();
    const tableRef = ref();
    const tabulator = ref();
    const filter = reactive({
      field: "sk_pangkat",
      type: "like",
      value: "",
    });
    const accessToken = store.getters["auth/accessToken"];
    const idSdm = store.state.userdata.userData.idSdm;

    const editRow = (e, cell) => {
      idData.value = cell.getData().id_rwy_inpassing;
      router.push({
        name: "edit-pangkat-inpassing",
        params: { id: idData.value },
      });
    };
    const initTabulator = () => {
      let columns = [
        {
          formatter: "responsiveCollapse",
          width: 40,
          minWidth: 30,
          hozAlign: "center",
          resizable: false,
          headerSort: false,
        },
        {
          title: "Golongan/Pangkat",
          responsive: true,
          field: "pangkat_golongan.nm_pangkat",
          headerHozAlign: "left",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            return (
              cell.getData().pangkat_golongan.nm_pangkat +
              " (" +
              cell.getData().angka_kredit +
              ")"
            );
          },
        },
        {
          title: "Nomor SK",
          responsive: true,
          field: "sk_pangkat",
          headerHozAlign: "left",
          vertAlign: "middle",
          print: false,
          download: false,
        },
        {
          title: "Terhitung Mulai Tanggal",
          responsive: true,
          headerHozAlign: "left",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            return helper.tanggal(cell.getData().tmt_sk_pangkat);
          },
        },
        {
          title: "Asal Data",
          responsive: true,
          headerHozAlign: "left",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            let a = "";
            if (cell.getData().id) {
              a = cash(
                `<div>
                <div class="text-blue-600 text-xs whitespace-normal">Sister</div>
                <span class="text-gray-500 text-xs whitespace-normal mt-2">Data terhubung ke SISTER PDDIKTI</span>
                </span>`
              );
            } else {
              a = cash(
                `<div class="text-blue-600 text-xs whitespace-normal">Internal</div>`
              );
            }

            return a[0];
          },
        },
        {
          title: "Status Ajuan",
          responsive: true,
          headerHozAlign: "left",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            let a = "";
            if (cell.getData().status_ajuan == 0) {
              a = cash(
                `<div class="py-1 px-2 rounded text-xs bg-primary-6 text-white cursor-pointer font-medium">Diajukan</div>`
              );
            } else if (cell.getData().status_ajuan == 1) {
              a = cash(
                `<div class="py-1 px-2 rounded text-xs bg-primary-4 text-white cursor-pointer font-medium">Disetujui</div>`
              );
            } else {
              a = cash(
                `<div class="py-1 px-2 rounded text-xs bg-primary-3 text-white cursor-pointer font-medium">Ditolak</div>`
              );
            }

            return a[0];
          },
        },
        {
          responsive: 1,
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          width: 85,
          formatter() {
            const a = cash(`<div class="flex lg:justify-center items-center">
                <a class="flex btn btn-sm btn-success w-18 items-center" href="javascript:;">
                  <i data-feather="archive" class="w-4 h-4 mr-1"></i> Open
                </a>
              </div>`);
            return a[0];
          },
          cellClick: editRow,
        },
      ];

      tabulator.value = tblTabulator.remoteDataTable(
        tableRef,
        sdm.InpassingSdm.endpoint + "/kode/" + idSdm,
        columns
      );
    };

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener("resize", () => {
        tabulator.value.redraw();
        feather.replace({
          "stroke-width": 1.5,
        });
      });
    };

    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value);
    };

    // On reset filter
    const onResetFilter = () => {
      filter.field = "sk_pangkat";
      filter.type = "like";
      filter.value = "";
      onFilter();
    };

    onMounted(() => {
      initTabulator();
      reInitOnResizeWindow();
    });

    return {
      store,
      tabulator,
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      accessToken,
      idData,
    };
  },
  data() {
    return {
      idSdm: this.$store.state.userdata.userData.idSdm,
      deleting: false,
      editing: false,
      dataTable: null,
      alertSuccess: this.$route.params.alertSuccess,
      messageAlert: this.$route.params.messageAlert,
    };
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
    deletingStatus() {
      return this.deleting;
    },
  },
  watch: {},
  mounted() {
    this.getuserdata();
  },
  methods: {
    getuserdata() {
      console.log(this.$store.state.userdata.userData);
    },
    getItems() {
      this.deleting = false;
      this.editing = false;
      this.tabulator.setData(
        sdm.InpassingSdm.endpoint + "/kode/" + this.idSdm,
        {},
        tblTabulator.ajaxConfig()
      );
    },
  },
});
</script>
